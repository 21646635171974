import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _622c29dc = () => interopDefault(import('../pages/blogs/index.vue' /* webpackChunkName: "pages/blogs/index" */))
const _4c5cc4b0 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _28be5746 = () => interopDefault(import('../pages/evenementen/index.vue' /* webpackChunkName: "pages/evenementen/index" */))
const _716c0dd3 = () => interopDefault(import('../pages/gegevens-aanpassen.vue' /* webpackChunkName: "pages/gegevens-aanpassen" */))
const _586aac19 = () => interopDefault(import('../pages/inloggen.vue' /* webpackChunkName: "pages/inloggen" */))
const _34c67b3c = () => interopDefault(import('../pages/magazine.vue' /* webpackChunkName: "pages/magazine" */))
const _0875aaac = () => interopDefault(import('../pages/magazines/index.vue' /* webpackChunkName: "pages/magazines/index" */))
const _464694ca = () => interopDefault(import('../pages/menu/index.vue' /* webpackChunkName: "pages/menu/index" */))
const _532c6242 = () => interopDefault(import('../pages/profiel.vue' /* webpackChunkName: "pages/profiel" */))
const _7eea98ad = () => interopDefault(import('../pages/recepten/index.vue' /* webpackChunkName: "pages/recepten/index" */))
const _8d1ef74c = () => interopDefault(import('../pages/registreren.vue' /* webpackChunkName: "pages/registreren" */))
const _574e1509 = () => interopDefault(import('../pages/services/index.vue' /* webpackChunkName: "pages/services/index" */))
const _7fb6bbd1 = () => interopDefault(import('../pages/verifieer.vue' /* webpackChunkName: "pages/verifieer" */))
const _55187867 = () => interopDefault(import('../pages/wachtwoord-aanpassen.vue' /* webpackChunkName: "pages/wachtwoord-aanpassen" */))
const _9ab6ce26 = () => interopDefault(import('../pages/wachtwoord-vergeten.vue' /* webpackChunkName: "pages/wachtwoord-vergeten" */))
const _70718315 = () => interopDefault(import('../pages/welbewust-assortiment/index.vue' /* webpackChunkName: "pages/welbewust-assortiment/index" */))
const _e77a2b88 = () => interopDefault(import('../pages/zoekresultaten.vue' /* webpackChunkName: "pages/zoekresultaten" */))
const _1794123d = () => interopDefault(import('../pages/menu/samenstellen.vue' /* webpackChunkName: "pages/menu/samenstellen" */))
const _5e782710 = () => interopDefault(import('../pages/services/aanvragen.vue' /* webpackChunkName: "pages/services/aanvragen" */))
const _32f3051a = () => interopDefault(import('../pages/templates/default.vue' /* webpackChunkName: "pages/templates/default" */))
const _022e154c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _60758894 = () => interopDefault(import('../pages/blogs/_slug.vue' /* webpackChunkName: "pages/blogs/_slug" */))
const _2c2b99d6 = () => interopDefault(import('../pages/evenementen/_slug.vue' /* webpackChunkName: "pages/evenementen/_slug" */))
const _0be2ed3c = () => interopDefault(import('../pages/magazines/_slug.vue' /* webpackChunkName: "pages/magazines/_slug" */))
const _7d33f765 = () => interopDefault(import('../pages/recepten/_slug.vue' /* webpackChunkName: "pages/recepten/_slug" */))
const _559773c1 = () => interopDefault(import('../pages/services/_slug.vue' /* webpackChunkName: "pages/services/_slug" */))
const _30831012 = () => interopDefault(import('../pages/wachtwoord-instellen/_token.vue' /* webpackChunkName: "pages/wachtwoord-instellen/_token" */))
const _6ebae1cd = () => interopDefault(import('../pages/welbewust-assortiment/_slug.vue' /* webpackChunkName: "pages/welbewust-assortiment/_slug" */))
const _7a7d4e9c = () => interopDefault(import('../pages/*.vue' /* webpackChunkName: "pages/*" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/blogs",
    component: _622c29dc,
    name: "blogs"
  }, {
    path: "/contact",
    component: _4c5cc4b0,
    name: "contact"
  }, {
    path: "/evenementen",
    component: _28be5746,
    name: "evenementen"
  }, {
    path: "/gegevens-aanpassen",
    component: _716c0dd3,
    name: "gegevens-aanpassen"
  }, {
    path: "/inloggen",
    component: _586aac19,
    name: "inloggen"
  }, {
    path: "/magazine",
    component: _34c67b3c,
    name: "magazine"
  }, {
    path: "/magazines",
    component: _0875aaac,
    name: "magazines"
  }, {
    path: "/menu",
    component: _464694ca,
    name: "menu"
  }, {
    path: "/profiel",
    component: _532c6242,
    name: "profiel"
  }, {
    path: "/recepten",
    component: _7eea98ad,
    name: "recepten"
  }, {
    path: "/registreren",
    component: _8d1ef74c,
    name: "registreren"
  }, {
    path: "/services",
    component: _574e1509,
    name: "services"
  }, {
    path: "/verifieer",
    component: _7fb6bbd1,
    name: "verifieer"
  }, {
    path: "/wachtwoord-aanpassen",
    component: _55187867,
    name: "wachtwoord-aanpassen"
  }, {
    path: "/wachtwoord-vergeten",
    component: _9ab6ce26,
    name: "wachtwoord-vergeten"
  }, {
    path: "/welbewust-assortiment",
    component: _70718315,
    name: "welbewust-assortiment"
  }, {
    path: "/zoekresultaten",
    component: _e77a2b88,
    name: "zoekresultaten"
  }, {
    path: "/menu/samenstellen",
    component: _1794123d,
    name: "menu-samenstellen"
  }, {
    path: "/services/aanvragen",
    component: _5e782710,
    name: "services-aanvragen"
  }, {
    path: "/templates/default",
    component: _32f3051a,
    name: "templates-default"
  }, {
    path: "/",
    component: _022e154c,
    name: "index"
  }, {
    path: "/blogs/:slug",
    component: _60758894,
    name: "blogs-slug"
  }, {
    path: "/evenementen/:slug",
    component: _2c2b99d6,
    name: "evenementen-slug"
  }, {
    path: "/magazines/:slug",
    component: _0be2ed3c,
    name: "magazines-slug"
  }, {
    path: "/recepten/:slug",
    component: _7d33f765,
    name: "recepten-slug"
  }, {
    path: "/services/:slug",
    component: _559773c1,
    name: "services-slug"
  }, {
    path: "/wachtwoord-instellen/:token?",
    component: _30831012,
    name: "wachtwoord-instellen-token"
  }, {
    path: "/welbewust-assortiment/:slug?",
    component: _6ebae1cd,
    name: "welbewust-assortiment-slug"
  }, {
    path: "/*",
    component: _7a7d4e9c,
    name: "*"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
